import i18n from '@/vue-app/plugins/i18n';
import TYPES from '@/types';
import { v4 } from 'uuid';
import Vue from 'vue';

// Application
import InvestmentSuggestionQuery
  from '@/modules/investment-suggestion/application/queries/investment-suggestion-query';
import InvestmentSuggestionCommand
  from '@/modules/investment-suggestion/application/commands/investment-suggestion-command';
import {
  FindAvailabilityToHireProductQuery,
} from '@/modules/my-investment/investment-product/application/queries';
import GetInvestmentProductsQuery from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';

// Domain
import {
  InvestmentSuggestionDto,
} from '@/modules/investment-suggestion/domain/dtos/investment-suggestion-dto';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/di/inject';

export default class InvestmentSuggestionPocketCardViewModel {
  @Inject(TYPES.GET_INVESTMENT_SUGGESTION_QUERY)
  private readonly get_investment_suggestion_query!: InvestmentSuggestionQuery;

  @Inject(TYPES.CREATE_INVESTMENT_SUGGESTION_COMMAND)
  private readonly create_investment_suggestion_command!: InvestmentSuggestionCommand;

  @Inject(TYPES.FIND_AVAILABILITY_TO_HIRE_PRODUCT_QUERY)
  private readonly find_availability_to_hire_product!: FindAvailabilityToHireProductQuery;

  @Inject(TYPES.GET_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_investment_products!: GetInvestmentProductsQuery;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  private readonly view!: Vue;

  readonly i18n_namespace = 'components.investment-suggestion.pocket_card';

  private investment_suggestion_dto: InvestmentSuggestionDto = {
    id: v4(),
    investment_suggestion_analytic_id: '',
    action: '',
  }

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (value: string) => (
    i18n.t(`${this.i18n_namespace}.${value}`) as string
  )

  obtainInvestmentSuggestion = async () => {
    try {
      const investment_suggestion = await this.get_investment_suggestion_query.execute();

      if (investment_suggestion) {
        this.investment_suggestion_dto.investment_suggestion_analytic_id = investment_suggestion.id;
      }
    } catch {
      this.message_notifier.showErrorNotification(
        `${i18n.tc(`${this.i18n_namespace}.message_error`)}`,
      );
    }
  }

  findAvailabilityToHire = async () => {
    let available_to_hire = false;
    const investment_products = await this.get_investment_products.execute();
    const investment_product = investment_products.find(
      (product) => product.name === 'sowos_pocket',
    );
    if (investment_product) {
      const availability = await this.find_availability_to_hire_product.execute(
        investment_product.id,
      );
      available_to_hire = availability.available_to_hire;
    }
    return available_to_hire;
  }

  createInvestmentSuggestionAction = async () => {
    try {
      this.investment_suggestion_dto.action = 'kuspit';
      await this.create_investment_suggestion_command.execute(this.investment_suggestion_dto);
      const available = await this.findAvailabilityToHire();
      if (!available) {
        this.view.$emit('showUnavailableProduct', 'SOWOS Pocket');
      } else {
        this.view.$emit('showModalPocket');
      }
      return true;
    } catch {
      this.message_notifier.showErrorNotification(
        `${i18n.tc(`${this.i18n_namespace}.message_error`)}`,
      );
      return false;
    }
  }
}
