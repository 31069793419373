import TYPES from '@/modules/shared/types';

// Domain
import {
  UploadVoucherDto,
} from '../../domain/dto/upload-voucher-dto';
import {
  SpecialContributionRepository,
} from '../../domain/repository/special-contribution-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class SpecialContributionHttpRepository implements
  SpecialContributionRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/allianz/special-contribution/`;

  notify(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}notification/`)
        .then((response) => {
          response.json()
            .then(({ success, data, error }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(error));
              }
            });
        })
        .catch(reject);
    });
  }

  uploadVoucher(upload_voucher_dto: UploadVoucherDto): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}upload-voucher/`, 'POST', upload_voucher_dto)
        .then((response) => {
          response.json()
            .then(({ success, data, error }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(error));
              }
            });
        })
        .catch(reject);
    });
  }
}
