




















































import { Component, Vue } from 'vue-property-decorator';

import InvestmentSuggestionModalViewModel
  from '@/vue-app/view-models/components/investment-suggestion/investment-suggestion-modal-view-model';
import InvestmentSuggestionWealthCard
  from '@/vue-app/components/investment-suggestion/InvestmentSuggestionWealthCard.vue';
import InvestmentSuggestionPocketCard
  from '@/vue-app/components/investment-suggestion/InvestmentSuggestionPocketCard.vue';
import InvestmentSuggestionFlagshipCard
  from '@/vue-app/components/investment-suggestion/InvestmentSuggestionFlagshipCard.vue';

@Component({
  name: 'InvestmentSuggestionModal',
  components: {
    InvestmentSuggestionWealthCard,
    InvestmentSuggestionPocketCard,
    InvestmentSuggestionFlagshipCard,
    ContractSavings: () => import('@/vue-app/components/ContractSavings.vue'),
    FlagshipProfilingModalSteps: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingModalSteps.vue'),
    PocketIsOnMaintenanceDialog: () => import('@/vue-app/components/my-investment-dashboard-v2/PocketIsOnMaintenanceDialog.vue'),
    ProductIsUnavailableToHireDialog: () => import('@/vue-app/components/my-investment-dashboard-v2/ProductIsUnavailableToHireDialog.vue'),
  },
})
export default class InvestmentSuggestionModal extends Vue {
  investment_modal_model = Vue.observable(new InvestmentSuggestionModalViewModel());

  created() {
    this.investment_modal_model.obtainInvestmentSuggestion();
  }
}
