// Domain
import {
  UploadVoucherDto,
} from '../../../domain/dto/upload-voucher-dto';
import {
  SpecialContributionRepository,
} from '../../../domain/repository/special-contribution-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class SpecialContributionDummyRepository implements
  SpecialContributionRepository {
  notify(): Promise<void> {
    return Promise.resolve();
  }

  uploadVoucher(upload_voucher_dto: UploadVoucherDto): Promise<void> {
    return Promise.resolve();
  }
}
